













import { defineComponent } from '@vue/composition-api'
import AppBarLanguageSwitcher from '@/components/AppBarLanguageSwitcher.vue'

export default defineComponent({
  name: 'LandingForMobileDevices',
  components: { AppBarLanguageSwitcher },
  data: () => ({ innerHeight }),
  mounted() {
    addEventListener('resize', () => (this.innerHeight = innerHeight))
  },
})
